import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AnalyseLinkedinResponse } from './type'

interface LinkedinDataState {
  url: null | string
  isLoading: boolean
  data: AnalyseLinkedinResponse | null
}

const initialState: LinkedinDataState = {
  url: null,
  isLoading: false,
  data: null,
}

export const linkdinSlice = createSlice({
  name: 'linkedin',
  initialState,
  reducers: {
    setLinkedinScore: (state, action: PayloadAction<AnalyseLinkedinResponse | null>) => {
      if (action.payload !== undefined) {
        state.data = action.payload
        state.isLoading = false
        state.url = null
      }
    },
    toggleLoading: (state) => {
      state.isLoading = !state.isLoading
    },
  },
})

// Action creators are generated for each case reducer function
export const { setLinkedinScore, toggleLoading } = linkdinSlice.actions

export default linkdinSlice.reducer
