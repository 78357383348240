import { mdiChartTimelineVariant } from '@mdi/js'
import React, { useState } from 'react'
import type { ReactElement } from 'react'
import Button from '../../components/Button'
import SectionMain from '../../components/Section/Main'
import SectionTitleLineWithButton from '../../components/Section/TitleLineWithButton'
import { config, getPageTitle } from '../../config'
import preview1 from '../../assets/preview1.png'
import preview2 from '../../assets/preview2.png'
import { useAppDispatch } from '../../stores/hooks'
import { useSelector } from 'react-redux'
import { RootState } from '../../stores/store'
import DataLoading from './loading/DataLoading'
import LayoutGuest from '../../layouts/Guest'
import axios from 'axios'
import { setLinkedinScore, toggleLoading } from '../../stores/mental-health'
import { useNavigate } from 'react-router-dom'
import './dashboard.css'
import { getProfilePictureUrl } from '../../hooks/linkedin'

const Dashboard = () => {
  const dispatch = useAppDispatch()
  const { isLoading } = useSelector((state: RootState) => state?.linkedin)
  const [linkedinUrl, setLinkedinUrl] = useState<string | null>(null)
  const [error, setError] = useState<string>('')
  const navigate = useNavigate()
  const analyseLinkedinUrl = async () => {
    try {
      if (linkedinUrl !== null) {
        dispatch(toggleLoading())
        // await getProfilePictureUrl(linkedinUrl!)
        const response = await axios.post(`${config.serverUrl}/linkedin`, {
          url: linkedinUrl.replace(/^\s+|\s+$/g, ''),
        })
        console.log(response)
        if (response.status === 200 && response.data?.success !== false) {
          if (response.data.scoring && response.data.scoring?.big5 && response.data.scoring?.neo) {
            dispatch(setLinkedinScore(response.data))
            navigate('/dashboard/result')
          } else {
            setError('Please check the provided URL')
          }
        } else {
          setError('Please check the provided URL')
        }
        dispatch(toggleLoading())
      }
    } catch (err) {
      console.log(err)
      dispatch(toggleLoading())
    }
  }

  return (
    <div className="dashboard">
      <div>
        <title>{getPageTitle('Dashboard')}</title>
      </div>
      <SectionMain>
        <SectionTitleLineWithButton
          icon={mdiChartTimelineVariant}
          title="Overview"
          main
        ></SectionTitleLineWithButton>

        {!isLoading ? (
          <div className="grid grid-cols-12 gap-6 mb-6 p-20 items-center justify-center h-screen">
            <div className="preview hidden md:inline-flex col-span-4 md:col-span-2">
              <img src={preview1} height={100} width={200} alt={''} />
            </div>
            <div className="relative col-span-12 md:row-span-2 md:col-span-8 text-center">
              <h4 className="text-5xl mb-10">Mental Health Assistance</h4>
              <div className="error">{error !== '' && error}</div>

              <div className="absolute inset-x-0 left-0 flex items-center p-4 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="search"
                className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Put Your Linkedin URL"
                style={{ fontSize: '20px' }}
                required
                onChange={(e) => setLinkedinUrl(e.target.value)}
              />
              <button
                type="submit"
                className="text-white  md:inline-flex md:absolute mt-5 end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-3 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={() => analyseLinkedinUrl()}
              >
                Get Your Results
              </button>
              {/* <button
                type="submit"
                className="text-white  md:inline-flex  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-3 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={() => analyseLinkedinUrl()}
              >
                Get Your Results
              </button> */}
            </div>
            <div className="preview  hidden md:inline-flex md:row-span-2 md:col-span-2">
              <img src={preview2} height={100} width={200} alt={''} />
            </div>
          </div>
        ) : (
          <DataLoading />
        )}
        {/* <SectionTitleLineWithButton icon={mdiChartPie} title="Trends overview">
            <Button icon={mdiReload} color="whiteDark" onClick={fillChartData} />
          </SectionTitleLineWithButton> */}
      </SectionMain>
    </div>
  )
}

Dashboard.getLayout = function getLayout(page: ReactElement) {
  return <LayoutGuest>{page}</LayoutGuest>
}

export default Dashboard
