import React from 'react'
import logo from './logo.svg'
import './App.css'
import Dashboard from './pages/dashboard'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import LinkedinAnalysisResult from './pages/dashboard/result'

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Dashboard />,
    },
    {
      path: '/dashboard',
      element: <Dashboard />,
    },
    {
      path: '/dashboard/result',
      element: <LinkedinAnalysisResult />,
    },
  ])
  return <RouterProvider router={router} />
}

export default App
