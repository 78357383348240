import React, { ReactElement, ReactNode } from 'react'
import { useAppSelector } from '../../stores/hooks'
import UserAvatar from '.'

type Props = {
  className?: string
  children?: ReactElement[]
}

export default function UserAvatarCurrentUser({ className = '', children }: Props) {
  const userEmail = useAppSelector((state) => state.main.userEmail)

  return (
    <UserAvatar username={userEmail!} className={className}>
      {children}
    </UserAvatar>
  )
}
