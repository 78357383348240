import { applyMiddleware, combineReducers, configureStore, createStore } from '@reduxjs/toolkit'
import darkModeReducer from './darkModeSlice'
import mainReducer from './mainSlice'
import linkedinReducer from './mental-health/index'
import { thunk } from 'redux-thunk'

const appReducer = combineReducers({
  darkMode: darkModeReducer,
  main: mainReducer,
  linkedin: linkedinReducer,
})

export const store = createStore(appReducer, applyMiddleware(thunk))

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
