export const containerMaxW = 'xl:max-w-1xl xl:mx-auto'

export const appTitle = 'Mental Health Assistance'

export const getPageTitle = (currentPageTitle: string) => `${currentPageTitle} — ${appTitle}`

const prod = {
  serverUrl: 'https://mhscore.devjee.com/api/linkedin',
}

const local = {
  serverUrl: 'http://localhost:7000',
}

export const config = prod
