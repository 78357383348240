import React from 'react'
import {
  Chart,
  LineElement,
  PointElement,
  LineController,
  LinearScale,
  CategoryScale,
  Tooltip,
  BarElement,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { AnalyseLinkedinResponse } from '../../stores/mental-health/type'
import './index.css'

Chart.register(
  LineElement,
  BarElement,
  PointElement,
  LineController,
  LinearScale,
  CategoryScale,
  Tooltip
)

Chart.defaults.font.size = 11
Chart.defaults.font.weight = 'bolder'
Chart.defaults.responsive = true

type ChartProps = {
  result: AnalyseLinkedinResponse
}

const ChartLineSample: React.FC<ChartProps> = ({ result }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        display: true,
      },
      x: {
        display: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    indexAxis: 'y' as const,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
  }

  const big5Data = result?.scoring?.big5
  const neoData = result?.scoring?.neo

  const chartData1 = {
    labels: Object.keys(big5Data).map((data) => data.toUpperCase()),

    datasets: [
      {
        label: 'Neo Score',
        axis: 'y',
        data: Object.values(big5Data).map((trait) => trait?.score),
        backgroundColor: Object.values(big5Data).map((trait) =>
          trait?.score > 0 ? '#17A478' : '#DF0909'
        ),
        borderWidth: 2,
        // barThickness: 40,
        // barPercentage: 50, // Adjust this value to increase the space between bars
        // categoryPercentage: 50,
      },
    ],
  }
  const chartData2 = {
    labels: Object.keys(neoData).map((data) => data.toUpperCase()),
    datasets: [
      {
        label: 'Neo Score',
        axis: 'y',
        data: Object.values(neoData).map((trait) => trait.score),
        backgroundColor: Object.values(neoData).map((trait) =>
          trait.score > 0 ? '#17A478' : '#DF0909'
        ),
        borderWidth: 2,
        // barThickness: 40,
        // barPercentage: 50, // Adjust this value to increase the space between bars
        // categoryPercentage: 50,
      },
    ],
  }
  return (
    <div className="linkedin-graph p-5">
      <h6 className="text-3xl">Big5 Results</h6>
      <div className="mb-20" style={{ height: '30vh', width: '100%', textAlign: 'left' }}>
        <Bar options={options} data={chartData1} height={'100%'} />
      </div>
      <h6 className="text-3xl">Neo Results</h6>
      <div className="mb-20" style={{ height: '150vh', width: '100%' }}>
        <Bar options={options} data={chartData2} height={'100%'} />
      </div>
    </div>
  )
}

export default ChartLineSample
