import React from 'react'
import type { ReactElement } from 'react'
import LayoutAuthenticated from '../../../layouts/Authenticated'
import SectionMain from '../../../components/Section/Main'
import { getPageTitle } from '../../../config'
import analyse from '../../../assets/analyse.png'
import preview2 from '../../../assets/preview2.png'
import linkedin from '../../../assets/linkedin.png'
import loading from '../../../assets/loading.gif'
import './dashboard-loading.css'

const DataLoading = () => {
  return (
    <div className="dashboard-data-loading">
      <div>
        <title>{getPageTitle('Dashboard Data-Loading')}</title>
      </div>
      <SectionMain>
        <div className="grid grid-cols-12 gap-6 mb-6 p-5 items-center justify-center">
          <div className="col col-span-12">
            <div className="analyse-title w-auto md:w-2/4">
              <img src={analyse} alt={''} height={500} className="w-96 md:h-80" />
              <h6 className="text-4xl uppercase">Analysing your Profile</h6>
            </div>
          </div>
          <div className="col col-span-12 ">
            <div className="loading p-0 m-0">
              <img src={loading} alt="Loading..." height={300} />
            </div>
          </div>
          {/* <div className="relative col-span-4">
            <img src={loading} alt="Loading..." height={300} />
          </div>
          <div className="linkedin col-span-4">
            <img src={''} height={100} width={100} alt={''} />
            <span className="overlay-icon">
              <img src={linkedin} alt="Linkedin" />
            </span>
          </div> */}
        </div>

        {/* <SectionTitleLineWithButton icon={mdiChartPie} title="Trends overview">
            <Button icon={mdiReload} color="whiteDark" onClick={fillChartData} />
          </SectionTitleLineWithButton> */}
      </SectionMain>
    </div>
  )
}

DataLoading.getLayout = function getLayout(page: ReactElement) {
  return <LayoutAuthenticated>{page}</LayoutAuthenticated>
}

export default DataLoading
