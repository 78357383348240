import React, { ReactElement, useEffect, useState } from 'react'
import { getPageTitle } from '../../../config'
import SectionMain from '../../../components/Section/Main'
import ChartLineSample from '../../../components/LinkedinGraph'
import { useSelector } from 'react-redux'
import { RootState } from '../../../stores/store'
import DataLoading from '../loading/DataLoading'
import LayoutGuest from '../../../layouts/Guest'
import CardBox from '../../../components/CardBox'
import './dashboard-result.css'

interface ScoringData {
  scoring: {
    big5: { [key: string]: { score: number } }
    neo: { [key: string]: { score: number } }
  }
}

const emojis = ['😢', '😔', '😑', '😃', '😊']

function rotateArrayAroundIndex(index: number) {
  if (index < 0 || index >= emojis.length) {
    throw new Error('Invalid index')
  }

  // Calculate the number of elements to rotate
  const numToRotate = emojis.length - index

  // Slice the array into two parts and concatenate them in reverse order
  return [...emojis.slice(numToRotate), ...emojis.slice(0, numToRotate)]
}

const LinkedinAnalysisResult = () => {
  const [score, setScore] = useState(0)
  const result = useSelector((state: RootState) => state?.linkedin.data)
  const [centerIndex, setCenterIndex] = useState<number | null>(null)
  console.log(result)
  const [scoreEmojis, setScoreEmojis] = useState<Array<String>>([])

  const calculateOverallScore = (data: ScoringData | null) => {
    if (data != null && data.scoring != null) {
      let totalScore = 0
      let count = 0

      // Calculate total score and count for big5
      for (const dimension in data.scoring.big5) {
        totalScore += data.scoring.big5[dimension].score
        count++
      }

      // Calculate total score and count for neo
      for (const dimension in data.scoring.neo) {
        totalScore += data.scoring.neo[dimension].score
        count++
      }

      // Calculate average score
      const averageScore = totalScore / count

      // Scale the average score to fit 0-100 range
      const scaledScore = Math.round((averageScore + 1) * 50)

      setScore(scaledScore)
      // Calculate the index of the emoji to display
      const index = Math.ceil(scaledScore / 20)
      setCenterIndex(index)
      setScoreEmojis(rotateArrayAroundIndex(index))
    }
  }

  useEffect(() => {
    calculateOverallScore(result)
  }, [result])

  return (
    <div className="linkedin-analysis-result">
      <div>
        <title>{getPageTitle('Dashboard')}</title>
      </div>
      {!result && <DataLoading />}
      {result && (
        <div className="max-w-6xl mx-auto">
          <div className="Score items-center p-2">
            <center>
              <h2 className="text-4xl  dark:text-white">Your mental health score is</h2>
              <h1 className="text-5xl font-extrabold dark:text-white">{score}/100</h1>
              <div className="score-bar my-5">
                <input type="range" width={'100%'} value={score} max={100} />
              </div>
              <div className="emojis-container flex my-5">
                {centerIndex &&
                  scoreEmojis.map((emoji, index) => (
                    <div
                      key={index}
                      className={`emoji-item flex-1 items-center justify-between  ${index === 2 ? ' text-7xl opacity-109 ' : 'text-5xl opacity-70'}`}
                    >
                      {emoji}
                    </div>
                  ))}
              </div>
            </center>
          </div>
          <div className="result-graph">
            <CardBox className="mb-6">
              <div className="report-data">
                <div className="report-title text-3xl ">Detailed Data Report</div>
                {result && result?.scoring && <ChartLineSample result={result} />}
                <div className="report-title text-3xl ">Your Summary</div>
                <div className="summary">
                  <ul>{result?.summary.split('. ').map((points) => <li>{points}.</li>)}</ul>
                </div>
              </div>
            </CardBox>
          </div>
        </div>
      )}
    </div>
  )
}
LinkedinAnalysisResult.getLayout = function getLayout(page: ReactElement) {
  return <LayoutGuest>{page}</LayoutGuest>
}

export default LinkedinAnalysisResult
